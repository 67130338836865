.notion {
  color: #37352f;
  caret-color: #37352f;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Apple Color Emoji, Arial, sans-serif, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
  line-height: 1.5;
}

.notion > *, .notion-page > *, .notion-column > * {
  padding: 3px 0;
}

.notion * {
  box-sizing: border-box;
  margin-block: 0;
}

.notion-red {
  color: #e03e3e;
}

.notion-pink {
  color: #ad1a72;
}

.notion-blue {
  color: #0b6e99;
}

.notion-purple {
  color: #6940a5;
}

.notion-teal {
  color: #0f7b6c;
}

.notion-yellow {
  color: #dfab01;
}

.notion-orange {
  color: #d9730d;
}

.notion-brown {
  color: #64473a;
}

.notion-gray {
  color: #9b9a97;
}

.notion-red_background {
  background-color: #fbe4e4;
}

.notion-pink_background {
  background-color: #f4dfeb;
}

.notion-blue_background {
  background-color: #ddebf1;
}

.notion-purple_background {
  background-color: #eae4f2;
}

.notion-teal_background {
  background-color: #ddedea;
}

.notion-yellow_background {
  background-color: #fbf3db;
}

.notion-orange_background {
  background-color: #faebdd;
}

.notion-brown_background {
  background-color: #e9e5e3;
}

.notion-gray_background {
  background-color: #ebeced;
}

.notion-red_background_co {
  background-color: #fbe4e44d;
}

.notion-pink_background_co {
  background-color: #f4dfeb4d;
}

.notion-blue_background_co {
  background-color: #ddebf14d;
}

.notion-purple_background_co {
  background-color: #eae4f24d;
}

.notion-teal_background_co {
  background-color: #ddedea4d;
}

.notion-yellow_background_co {
  background-color: #fbf3db4d;
}

.notion-orange_background_co {
  background-color: #faebdd4d;
}

.notion-brown_background_co {
  background-color: #e9e5e34d;
}

.notion-gray_background_co {
  background-color: #ebeced4d;
}

.notion b {
  font-weight: 600;
}

.notion-title {
  margin-top: .75em;
  margin-bottom: .25em;
  font-size: 2.5em;
  font-weight: 700;
}

.notion-h1, .notion-h2, .notion-h3 {
  padding: 3px 2px;
  font-weight: 600;
  line-height: 1.3;
}

.notion-h1 {
  margin-top: 1.4em;
  font-size: 1.875em;
}

.notion-h1:first-child {
  margin-top: 0;
}

.notion-h2 {
  margin-top: 1.1em;
  font-size: 1.5em;
}

.notion-h3 {
  margin-top: 1em;
  font-size: 1.25em;
}

.notion-emoji {
  font-family: Apple Color Emoji, Arial, sans-serif, Segoe UI Emoji, Segoe UI Symbol;
}

.notion-page-cover {
  object-fit: cover;
  width: 100%;
  height: 30vh;
  min-height: 30vh;
  padding: 0;
  display: block;
}

.notion-page {
  max-width: 708px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

@media only screen and (max-width: 730px) {
  .notion-page {
    padding: 0 2vw;
  }
}

.notion-page-offset {
  margin-top: 96px;
}

span.notion-page-icon-cover {
  height: 78px;
  width: 78px;
  margin-left: 0;
  font-size: 78px;
  line-height: 1.1;
  display: inline-block;
}

span.notion-page-icon-offset {
  margin-top: -42px;
}

img.notion-page-icon-cover {
  width: 124px;
  height: 124px;
  border-radius: 3px;
  margin: 8px;
}

img.notion-page-icon-offset {
  margin-top: -80px;
}

.notion-full-width {
  max-width: 100%;
  padding: 0 40px;
}

.notion-small-text {
  font-size: 14px;
}

.notion-quote {
  white-space: pre-wrap;
  word-break: break-word;
  border-left: 3px solid;
  margin: 0;
  padding: .2em .9em;
  font-size: 1.2em;
}

.notion-hr {
  border-color: #37352f17;
  border-top-width: 1px;
  border-bottom-width: 0;
  margin: 6px 0;
  padding: 0;
}

.notion-link {
  color: inherit;
  word-break: break-word;
  text-decoration: underline;
  text-decoration-color: inherit;
}

.notion-blank {
  min-height: 1rem;
  margin-top: 1px;
  margin-bottom: 1px;
  padding: 3px 2px;
}

.notion-page-link {
  color: #37352f;
  height: 30px;
  margin: 1px 0;
  text-decoration: none;
  transition: background .12s ease-in;
  display: flex;
}

.notion-page-link:hover {
  background: #37352f14;
}

.notion-page-icon {
  margin-left: 2px;
  margin-right: 4px;
  line-height: 1.4;
}

img.notion-page-icon {
  object-fit: cover;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  display: block;
}

.notion-icon {
  width: 18px;
  height: 18px;
  color: #37352f66;
  display: block;
}

.notion-page-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid #37352f29;
  margin: 1px 0;
  font-weight: 500;
  line-height: 1.3;
  overflow: hidden;
}

.notion-inline-code {
  color: #eb5757;
  background: #87837826;
  border-radius: 3px;
  padding: .2em .4em;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  font-size: 85%;
}

.notion-list {
  margin: 0;
  margin-block: .6em;
}

.notion-list-disc {
  margin-top: 0;
  margin-bottom: 0;
  padding-inline-start: 1.6em;
  list-style-type: disc;
}

.notion-list-numbered {
  margin-top: 0;
  margin-bottom: 0;
  padding-inline-start: 1.6em;
  list-style-type: decimal;
}

.notion-list-disc li {
  padding-left: .1em;
}

.notion-list-numbered li {
  padding-left: .2em;
}

.notion-list li {
  white-space: pre-wrap;
  padding: 4px 0;
}

.notion-list > .notion-text {
  margin-left: -1.6em;
  padding-left: 0;
}

.notion-asset-wrapper {
  max-width: 100%;
  margin: .5rem auto;
}

.notion-asset-wrapper > img {
  max-width: 100%;
}

.notion-asset-wrapper iframe {
  border: none;
}

.notion-text {
  white-space: pre-wrap;
  caret-color: #37352f;
  padding: 3px 2px;
}

.notion-block {
  padding: 3px 2px;
}

.notion .notion-code {
  font-size: 85%;
}

.notion-code {
  tab-size: 2;
  box-sizing: border-box;
  background: #f7f6f3;
  border-radius: 3px;
  margin: 4px 0;
  padding: 30px 16px 30px 20px;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  display: block;
  overflow-x: scroll;
}

.notion-column {
  padding-top: 12px;
  padding-bottom: 12px;
}

.notion-column > :first-child {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.notion-column > :last-child {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.notion-row {
  display: flex;
  overflow: hidden;
}

.notion-bookmark {
  width: 100%;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #37352f29;
  border-radius: 3px;
  margin: 4px 0;
  text-decoration: none;
  display: flex;
  overflow: hidden;
}

.notion-bookmark > div:first-child {
  text-align: left;
  color: #37352f;
  flex: 4 180px;
  padding: 12px 14px 14px;
  overflow: hidden;
}

.notion-bookmark-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 24px;
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
}

.notion-bookmark-description {
  opacity: .6;
  height: 32px;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
}

.notion-bookmark-link {
  margin-top: 6px;
  display: flex;
}

.notion-bookmark-link > img {
  width: 16px;
  height: 16px;
  min-width: 16px;
  margin-right: 6px;
}

.notion-bookmark-link > div {
  color: #37352f;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
}

.notion-bookmark-image {
  flex: 180px;
  position: relative;
}

.notion-bookmark-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

.notion-column .notion-bookmark-image {
  display: none;
}

@media (max-width: 640px) {
  .notion-bookmark-image {
    display: none;
  }

  .notion-row {
    flex-direction: column;
  }

  .notion-row > *, .notion-column > * {
    width: 100% !important;
  }
}

.notion-spacer:last-child {
  display: none;
}

.notion-image-inset {
  width: 100%;
  height: 100%;
  border-radius: 1px;
  position: absolute;
  inset: 0;
}

.notion-image-caption {
  white-space: pre-wrap;
  word-break: break-word;
  caret-color: #37352f;
  color: #37352f99;
  padding: 6px 0;
  font-size: 14px;
  line-height: 1.4;
}

.notion-callout {
  width: 100%;
  box-sizing: border-box;
  border-width: 1px;
  border-radius: 3px;
  align-items: center;
  margin: 4px 0;
  padding: 16px 16px 16px 12px;
  display: inline-flex;
}

.notion-callout-text {
  white-space: pre-line;
  margin-left: 8px;
}

.notion-toggle {
  padding: 3px 2px;
}

.notion-toggle > summary {
  cursor: pointer;
  outline: none;
}

.notion-toggle > div {
  margin-left: 1.1em;
}

.notion-table, .notion-th, .notion-td {
  border-collapse: collapse;
  border: 1px solid #37352f17;
}

.notion-table {
  width: 100%;
  border-spacing: 0;
  white-space: nowrap;
  border-left: none;
  border-right: none;
}

.notion-th, .notion-td {
  min-height: 1.5em;
  text-align: left;
  padding: .25em .5em;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
}

.notion-td.notion-bold {
  font-weight: 500;
}

.notion-th {
  color: #37352f99;
  font-size: 14px;
}

.notion-td:first-child, .notion-th:first-child {
  border-left: 0;
}

.notion-td:last-child, .notion-th:last-child {
  border-right: 0;
}

.notion-gallery {
  border-top: 1px solid #37352f29;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-auto-rows: 1fr;
  gap: 16px;
  padding-top: 16px;
  padding-bottom: 4px;
  display: grid;
  position: relative;
}

.notion-gallery-card {
  color: inherit;
  height: 100%;
  background: #fff;
  border-radius: 3px;
  text-decoration: none;
  transition: background .1s ease-out;
  display: block;
  position: static;
  overflow: hidden;
  box-shadow: 0 0 0 1px #0f0f0f1a, 0 2px 4px #0f0f0f1a;
}

.notion-gallery-content {
  white-space: nowrap;
  padding: 8px 10px 6px;
  font-size: 12px;
}

.notion-gallery-data.is-first {
  white-space: nowrap;
  word-break: break-word;
  caret-color: #37352f;
  min-height: 21px;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  overflow: hidden;
}

.notion-page-header {
  width: 100%;
  max-width: 100vw;
  height: 45px;
  min-height: 45px;
  box-sizing: border-box;
  text-size-adjust: 100%;
  background: #fff;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  position: sticky;
  top: 0;
}

.notion-nav-breadcrumbs {
  height: 100%;
  min-width: 0;
  flex-direction: row;
  flex-grow: 0;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.notion-nav-breadcrumb {
  white-space: nowrap;
  color: #37352f;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  background: none;
  border-radius: 3px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1px 0;
  padding: 4px 6px;
  text-decoration: none;
  transition: background .12s ease-in;
  display: inline-flex;
}

img.notion-nav-icon {
  width: 18px !important;
  height: 18px !important;
}

.notion-nav-icon {
  color: #000;
  margin-right: 6px;
  font-size: 18px;
  line-height: 1.1;
}

.notion-nav-breadcrumb:not(.notion-nav-breadcrumb-active):hover {
  background: #37352f14;
}

.notion-nav-breadcrumb:not(.notion-nav-breadcrumb-active):active {
  background: #37352f29;
}

.notion-nav-breadcrumb.notion-nav-breadcrumb-active {
  cursor: default;
}

.notion-nav-spacer {
  color: #37352f66;
  margin: 0 2px;
}

code[class*="language-"], pre[class*="language-"] {
  color: #ccc;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  -webkit-hyphens: none;
  hyphens: none;
  background: none;
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  font-size: 1em;
  line-height: 1.5;
}

pre[class*="language-"] {
  margin: .5em 0;
  padding: 1em;
  overflow: auto;
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: #2d2d2d;
}

:not(pre) > code[class*="language-"] {
  white-space: normal;
  border-radius: .3em;
  padding: .1em;
}

.token.comment, .token.block-comment, .token.prolog, .token.doctype, .token.cdata {
  color: #999;
}

.token.punctuation {
  color: #ccc;
}

.token.tag, .token.attr-name, .token.namespace, .token.deleted {
  color: #e2777a;
}

.token.function-name {
  color: #6196cc;
}

.token.boolean, .token.number, .token.function {
  color: #f08d49;
}

.token.property, .token.class-name, .token.constant, .token.symbol {
  color: #f8c555;
}

.token.selector, .token.important, .token.atrule, .token.keyword, .token.builtin {
  color: #cc99cd;
}

.token.string, .token.char, .token.attr-value, .token.regex, .token.variable {
  color: #7ec699;
}

.token.operator, .token.entity, .token.url {
  color: #67cdcc;
}

.token.important, .token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.token.inserted {
  color: green;
}

:root {
  --textColor: black;
  --primaryColor: #0433a1;
  --secondaryColor: #fff2df;
  --tertiaryColor: #eb4a1c;
  --yellowColor: #fbc141;
  --mobileBreakpoint: 600px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Lato, sans-serif;
}

html {
  background-color: var(--secondaryColor);
}

h1 {
  margin-bottom: 10px;
  font-size: 2rem;
}

.hiya-frog-wrapper {
  position: relative;
}

.hiya-frog-wrapper > p {
  position: absolute;
  top: 50px;
  right: -80px;
}

.contact-link {
  height: 100%;
  margin-right: 15px;
  display: block;
}

.contact-link:last-of-type {
  margin-right: 0;
}

.contact-link-img {
  width: 100%;
  height: 100%;
}

.contact-link-img:hover {
  transition: transform .1s ease-in-out;
  transform: scale(1.2)translateY(-5px);
}

.nav-logo {
  height: 150px;
}

.nav-list {
  justify-content: center;
  align-items: center;
  display: flex;
}

.nav-list > li {
  margin: 0 10px;
  font-size: 1.2rem;
  font-weight: bold;
  display: inline;
}

.nav-base {
  color: var(--textColor);
  text-decoration: none;
  display: inline;
}

.nav-active {
  color: var(--primaryColor);
  border-bottom: 2px solid var(--yellowColor);
}

.nav-inactive {
  transition: color .2s ease-out;
}

.nav-inactive:hover {
  color: var(--primaryColor);
}

.flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

.flex-column {
  flex-direction: column;
  display: flex;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
  .content-wrapper {
    padding: 0 20px;
  }

  .nav-list {
    height: 130px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .nav-base {
    font-size: 1.3rem;
  }
}

.word-box {
  border: 3px solid #000;
  padding: 5px 20px;
}

.hero-gif {
  aspect-ratio: 1 / 1;
  width: 100%;
  max-width: 400px;
  object-fit: cover;
  filter: grayscale(90%);
  border: 15px solid #fff;
  margin-top: 20px;
}

.app-wrapper {
  width: 100%;
  min-width: 350px;
  min-height: 100vh;
  min-height: 100svh;
  flex-direction: column;
  margin: 0 0 10px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.nav-bar-wrapper {
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
  display: flex;
}

.main-content-wrapper {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  align-self: flex-start;
  padding: 0 20px;
  display: flex;
}

.footer-wrapper {
  height: 45px;
  width: 150px;
  border-bottom: 5px solid var(--yellowColor);
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 5px;
  display: flex;
}

.projects-wrapper {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  display: grid;
}

.project-card {
  width: 200px;
  height: 200px;
  border-top: 5px solid var(--primaryColor);
  border-left: 5px solid var(--primaryColor);
}

.project-card:hover {
  border-top: 5px solid var(--tertiaryColor);
  border-left: 5px solid var(--tertiaryColor);
}

.notion-doc-wrapper {
  width: 80%;
}

/*# sourceMappingURL=index.5376c487.css.map */
